<template>
  <div class="content-page">
    <div class="content-nav">
      <el-breadcrumb class="breadcrumb" separator="/">
        <el-breadcrumb-item :to="{ name: 'news' }">职位管理</el-breadcrumb-item>
        <el-breadcrumb-item>{{
          infoForm.id ? "编辑职位" : "添加职位"
        }}</el-breadcrumb-item>
      </el-breadcrumb>
      <div class="operation-nav">
        <el-button type="primary" @click="onSubmitInfo">确定保存</el-button>
        <el-button @click="goBackPage" icon="arrow-left">返回列表</el-button>
      </div>
    </div>
    <div class="content-main">
      <div class="form-table-box">
        <el-form
          ref="infoForm"
          :rules="infoRules"
          :model="infoForm"
          label-width="120px"
        >
          <el-form-item label="职位名称" prop="title">
            <el-input v-model="infoForm.title"></el-input>
          </el-form-item>
          <el-form-item label="招聘人数" prop="title">
            <el-input v-model="infoForm.num"></el-input>
          </el-form-item>
          <el-form-item label="职位描述与要求" prop="content">
            <el-input
              type="textarea"
              :rows="5"
              v-model="infoForm.content"
            ></el-input>
          </el-form-item>

          <el-form-item label="岗位性质" prop="author">
            <el-select v-model="infoForm.type" placeholder="请选择">
              <el-option
                v-for="item in TypeOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="经验要求" prop="experience">
            <el-select v-model="infoForm.experience" placeholder="请选择">
              <el-option
                v-for="item in experienceOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="薪资范围" prop="minSalary">
            <el-select
              v-model="infoForm.minSalary"
              placeholder="最低薪资"
              @change="selectMinSalery"
            >
              <el-option
                v-for="item in minSalaryOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
            <el-select
              v-model="infoForm.maxSalary"
              placeholder="最高薪资"
              v-if="showMaxSalertSelect"
            >
              <el-option
                v-for="item in maxSalaryOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="工作地点" prop="address">
            <el-input v-model="infoForm.address"></el-input>
          </el-form-item>
          <el-form-item label="联系电话" prop="phone">
            <el-input v-model="infoForm.phone"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="onSubmitInfo">确定保存</el-button>
            <el-button @click="goBackPage">返回列表</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <el-dialog v-model="dialogVisible" size="tiny">
      <img width="100%" :src="dialogImageUrl" alt="" />
    </el-dialog>
  </div>
</template>

<script>
import api from "@/config/api";
import $ from "jquery";
import { quillEditor } from "vue-quill-editor";
import ElForm from "element-ui/packages/form/src/form.vue";
import ElFormItem from "element-ui/packages/form/src/form-item.vue"; //调用富文本编辑器
const toolbarOptions = [
  ["bold", "italic", "underline", "strike"],
  ["blockquote", "code-block"],
  [{ header: 1 }, { header: 2 }],
  [{ list: "ordered" }, { list: "bullet" }],
  [{ script: "sub" }, { script: "super" }],
  [{ indent: "-1" }, { indent: "+1" }],
  [{ direction: "rtl" }],
  [{ size: ["small", false, "large", "huge"] }],
  [{ header: [1, 2, 3, 4, 5, 6, false] }],
  [{ font: [] }],
  [{ color: [] }, { background: [] }],
  [{ align: [] }],
  ["clean"],
  ["link", "image", "video"],
];
export default {
  name: "GoodsAddPage",
  data() {
    return {
      TypeOptions: [
        {
          value: "全职",
          label: "全职",
        },
        {
          value: "兼职",
          label: "兼职",
        },
      ],
      type: "",
      experienceOptions: [
        {
          value: "不限",
          label: "不限",
        },
        {
          value: "1年以内",
          label: "1年以内",
        },
        {
          value: "1-3年",
          label: "1-3年",
        },
        {
          value: "3-5年",
          label: "3-5年",
        },
        {
          value: "5-10年",
          label: "5-10年",
        },
        {
          value: "10年以上",
          label: "10年以上",
        },
      ],
      experience: "",
      minSalary: "",
      maxSalary: "",
      showMaxSalertSelect: false,
      maxSalaryOptions: [],
      minSalaryOptions: [
        {
          value: "电议",
          label: "电议",
        },
        {
          value: "1000",
          label: "1000",
        },
        {
          value: "2000",
          label: "2000",
        },
        {
          value: "3000",
          label: "3000",
        },
        {
          value: "4000",
          label: "4000",
        },
        {
          value: "5000",
          label: "5000",
        },
        {
          value: "6000",
          label: "6000",
        },
        {
          value: "7000",
          label: "7000",
        },
        {
          value: "8000",
          label: "8000",
        },
        {
          value: "9000",
          label: "9000",
        },
        {
          value: "10000",
          label: "10000",
        },
        {
          value: "11000",
          label: "11000",
        },
        {
          value: "12000",
          label: "12000",
        },
        {
          value: "13000",
          label: "13000",
        },
        {
          value: "14000",
          label: "14000",
        },
        {
          value: "15000",
          label: "15000",
        },
        {
          value: "16000",
          label: "16000",
        },
        {
          value: "17000",
          label: "17000",
        },
        {
          value: "18000",
          label: "18000",
        },
        {
          value: "19000",
          label: "19000",
        },
        {
          value: "20000",
          label: "20000",
        },
        {
          value: "21000",
          label: "21000",
        },
        {
          value: "22000",
          label: "22000",
        },
        {
          value: "23000",
          label: "23000",
        },
        {
          value: "24000",
          label: "24000",
        },
        {
          value: "25000",
          label: "25000",
        },
        {
          value: "26000",
          label: "26000",
        },
        {
          value: "27000",
          label: "27000",
        },
        {
          value: "28000",
          label: "28000",
        },
        {
          value: "29000",
          label: "29000",
        },
        {
          value: "30000",
          label: "30000",
        },
        {
          value: "35000",
          label: "35000",
        },
        {
          value: "40000",
          label: "40000",
        },
        {
          value: "45000",
          label: "45000",
        },
        {
          value: "50000",
          label: "50000",
        },
        {
          value: "55000",
          label: "55000",
        },
        {
          value: "60000",
          label: "60000",
        },
        {
          value: "65000",
          label: "65000",
        },
        {
          value: "70000",
          label: "70000",
        },
        {
          value: "75000",
          label: "75000",
        },
        {
          value: "80000",
          label: "80000",
        },
      ],
      root: "",

      dialogImageUrl: "",
      dialogVisible: false,
      options: [],
      uploaderHeader: {
        "I-WanBo-Token": localStorage.getItem("token") || "",
      },

      infoForm: {
        title: "",
        num: "",
        content: "",
        type: "",
        experience: "",
        minSalary: "",
        maxSalary: "",
        address: "",
        phone: "",
      },
      addressPhoneList:[],
      infoRules: {
        title: [{ required: true, message: "请输入岗位名称", trigger: "blur" }],
        num: [{ required: true, message: "请输入招聘人数", trigger: "blur" }],
        content: [
          { required: true, message: "请输入职位要求", trigger: "blur" },
        ],
        type: [{ required: true, message: "请输入岗位性质", trigger: "blur" }],
        experience: [
          { required: true, message: "请输入经验要求", trigger: "blur" },
        ],
        minSalary: [{ required: true, message: "请输入薪资", trigger: "blur" }],
        maxSalary: [{ required: true, message: "请输入薪资", trigger: "blur" }],
        address: [
          { required: true, message: "请输入工作地点", trigger: "blur" },
        ],
        phone: [{ required: true, message: "请输入联系电话", trigger: "blur" }],
      },
    };
  },
  methods: {
    selectMinSalery() {
      let minsalery = this.infoForm.minSalary;

      if (minsalery != "电议") {
        let maxOptions = [];
        for (let item of this.minSalaryOptions) {
          if (parseInt(minsalery) < parseInt(item.value)) {
            maxOptions.push(item);
          }
        }
        this.maxSalaryOptions = maxOptions;
        this.showMaxSalertSelect = true;
      }else{
        this.maxSalary = ''
        this.showMaxSalertSelect = false;
      }
    },

    selectBlur(e) {
      // this.searchForm.fileExt = e.target.value
    },

    submitUpload() {
      this.$refs.upload.submit();
    },

    goBackPage() {
      this.$router.push({
        name: "recuirt",
        query: { page: this.$route.query.page || 1 },
      });
    },

    onSubmitInfo() {
      this.$refs["infoForm"].validate((valid) => {
        if (!valid) {
          this.$message({
            type: "error",
            message: "信息未填写完整",
          });
          return false;
        }
        const loading = this.$loading({
          lock: true,
          text: "正在保存中...",
          spinner: "el-icon-loading",
          background: "rgba(0, 0, 0, 0.5)",
        });
        this.axios
          .post("recuirt/store", {
            info: this.infoForm,
          })
          .then((response) => {
            loading.close();
            if (response.data.errno === 0) {
              this.$message({
                type: "success",
                message: "保存成功",
              });
              this.$router.go(-1);
            } else {
              this.$message({
                type: "error",
                message: response.data.errmsg
              });
            }
          });
      });
    },
  getAddressPhoneList() {
      
      let that = this;
      this.axios
        .get("recuirt/addressPhoneList")
        .then((response) => {
          let resInfo = response.data.data;
          this.infoForm.address = resInfo.address;
          this.infoForm.phone = resInfo.phone;
        });
    },
    getInfo() {
      if (this.infoForm.id <= 0) {
        return false;
      }
      //加载商品详情
      let that = this;
      this.axios
        .get("recuirt/info", {
          params: {
            id: that.infoForm.id,
          },
        })
        .then((response) => {
          let resInfo = response.data.data;
          that.infoForm = resInfo;
          that.getImgUrl();
        });
    },
  },
  components: {
    ElFormItem,
    ElForm,
    quillEditor,
  },
  computed: {
    editor() {
      return this.$refs.myTextEditor.quillEditor;
    },
  },
  mounted() {
    this.infoForm.id = this.$route.query.id || 0;
    if(this.infoForm.id > 0){
    this.getInfo();

    }else{
    this.getAddressPhoneList();

    }
    
    // this.getQiniuToken();
    this.root = api.rootUrl;
    this.qiniuZone = api.qiniu;
  },
};
</script>

<style lang="scss" scoped>
/* .edit_container{ */
/*.avatar-uploader .el-upload {*/
/*display: none;*/
/*}*/

.video-wrap {
  width: 300px;
}
.dialog-header {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 10px;
}

.dialog-header .value {
  width: 150px;
  margin-right: 14px;
}

.input-wrap .el-input {
  width: 200px;
  float: left;
  margin: 0 20px 20px 0;
}

.input-wrap .el-input input {
  background-color: #fff !important;
  color: #233445 !important;
}

.specFormDialig .el-input {
  width: 150px;
  margin-right: 10px;
}

.el-select-class {
  width: 200px;
  margin-right: 20px;
}

.sepc-form {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 10px;
}

.spec-form-wrap {
  margin-top: 0 !important;
}

.add-spec {
  margin-top: 10px;
}

.spec-form-wrap .header {
  display: flex;
  justify-content: flex-start;
}

.spec-form-wrap .header .l {
  width: 200px;
  margin-right: 20px;
}

.spec-form-wrap .header .m {
  width: 200px;
  margin-right: 20px;
}

.spec-form-wrap .header .m {
  width: 200px;
  margin-right: 20px;
}
.video-avatar {
  width: 148px;
  height: 148px;
}

/*.sepc-form div{*/
/*margin-left: 0;*/
/*}*/

.float-right {
  float: right;
}

.sepc-form .el-input {
  width: 200px;
  margin-right: 20px;
}

.marginTop20 {
  margin-top: 20px;
}

.checkbox-wrap .checkbox-list {
  float: left;
  margin-right: 20px;
}

.upload_ad {
  display: none;
}
.upload_ad .el-upload--picture-card {
  display: none;
}

.ql-container {
  min-height: 200px;
  max-height: 400px;
  overflow-y: auto;
}

.image-uploader-diy {
  /*height: 200px;*/
  position: relative;
}

/*.dele-list-pic {*/
/*position: absolute;*/
/*left: 380px;*/
/*top: 0px;*/
/*}*/

.image-uploader-diy .el-upload {
  border: 1px solid #d9d9d9;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.image-uploader-diy .el-upload:hover {
  border-color: #20a0ff;
}

.image-uploader-diy .image-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 200px;
  height: 200px;
  line-height: 200px;
  text-align: center;
}

.image-uploader-diy .image-show {
  min-width: 105px;
  height: 105px;
  display: block;
}

.image-uploader-diy .new-image-uploader {
  font-size: 28px;
  color: #8c939d;
  width: 165px;
  height: 105px;
  line-height: 105px;
  text-align: center;
}

.image-uploader-diy .new-image-uploader .image-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 165px;
  height: 105px;
  line-height: 105px;
  text-align: center;
}

.image-uploader-diy .new-image-uploader .image-show {
  width: 165px;
  height: 105px;
  display: block;
}

.item-url-image-fuzhu .el-input {
  width: 260px;
}

.hidden {
  display: none;
}
.spec-uploader /deep/ .el-upload {
  width: 50px;
  height: 50px;
  position: relative;
}
</style>
